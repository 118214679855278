import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../../composants/image/Image"
import LayoutTutorielEN from "../../../composants/layout/en/LayoutTutorielEN"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"
export const requete = graphql`
  {
    pageYamlLogicielsAstrophotographie: allPagesCompilationYaml(
      filter: { code: { eq: "logicielsAstrophotographieEN" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielAstrophotographieEN"
            "previsionsCouvertureNuageuseEN"
            "galerieVoieLacteeEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "logicielsLelogicielSequenceGeneratorPro"
            "logicielsLelogicielAstroPixelProcessor"
            "logicielsLelogicielCartesDuCiel"
            "logicielsLelogicielStellarium"
            "logicielsNina"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["orion"] } }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function AstrophotographySoftwares() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(
    resultatsRequete,
    "pageYamlLogicielsAstrophotographie"
  )
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return (
    <LayoutTutorielEN
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Preamble">
        <p>
          Here is a jumble of the most effective software to practice
          astrophotography. In 15 years of practice, I have tested a very large
          number… so here is a small selection. Keep in mind that all of them
          will require work and patience. This is the rule of the game to
          progress in astrophotography…
        </p>
        <Image
          image={obtenirImage(images, "orion")}
          langue={page.langue}
          afficherTitre={false}
          legende="A mosaic of the entire constellation of Orion, the culmination of 15 years of practice."
        />
      </Section>
      <Section titre="Planetariums">
        <p>
          Planetariums are software used to simulate the sky. They are ideal for
          preparing for astrophotographic sessions (sunrise and sunset, Moon,
          etc.).
        </p>
        <ul>
          <li>
            <Lien urlExterne="https://stellarium.org">
              <strong>Stellarium</strong>
            </Lien>{" "}
            (Windows, Mac, Linux): this planetarium allows you to simulate “all”
            the sky using an <strong>immersive 3D view</strong>: dance of
            constellations in the sky, satellites… It is ideal to prepare an
            astrophotographic session. But personally, its user interface
            confuses me a bit.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "logicielsLelogicielStellarium")}
          langue={page.langue}
        />
        <ul>
          <li>
            <Lien urlExterne="https://www.ap-i.net/skychart">
              <strong>Skychart</strong>
            </Lien>{" "}
            (Windows, Mac, Linux): a perfect software to prepare{" "}
            <strong>deep sky astrophotography sessions</strong>, but also to
            control a telescope. This is my favorite. Skychart does not have an
            “immersive” view like Stellarium: as its name suggests, it displays
            a sky map and and it is <strong>complementary</strong> to it.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "logicielsLelogicielCartesDuCiel")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Image processing">
        <ul>
          <li>
            <Lien urlExterne="https://www.adobe.com/fr/products/photoshop.html">
              <strong>Photoshop</strong>
            </Lien>{" "}
            (€ 11.99 / month with Lightroom) (Windows, Mac): the essential
            software to process your images. It asks patience to be mastered but
            you will find many Internet resources. Photoshop is not free but
            there is no as effective alternative yet…
          </li>
          <li>
            <Lien urlExterne="https://pixinsight.com/">
              <strong>Pixinsight</strong>
            </Lien>{" "}
            (€ 230, free trial version) (Windows, Mac, Linux): if you you are
            interested in astrophotography, you probably have heard of this
            software. Very powerful, but with a <strong>complex</strong>{" "}
            handling, it is to be reserved to <strong>advanced</strong> and{" "}
            <strong>passionate</strong> users.
          </li>
        </ul>
      </Section>
      <Section titre="Star trails">
        <ul>
          <li>
            <Lien urlExterne="https://markus-enzweiler.de/software/starstax/">
              <strong>Star Stax</strong>
            </Lien>{" "}
            (Windows, Mac): this software allows you to stack your star trails.
            Unlike others it is capable to <strong>fill the “holes”</strong>{" "}
            between the trails caused by the time interval between two
            consecutive shots.
          </li>
        </ul>
      </Section>
      <Section titre="Milky Way images processing">
        <ul>
          <li>
            <Lien urlExterne="https://sites.google.com/site/sequatorglobal/">
              <strong>Sequator</strong>
            </Lien>{" "}
            (Windows): this excellent little free software has many features but
            above all it can <strong>stack a series of identical shots</strong>{" "}
            by separating the Milky Way (which moves because the Earth rotates)
            and the ground (which remains fixed) even if you are not equipped
            with an equatorial mount.
          </li>
          <li>
            <Lien urlExterne="https://sites.google.com/site/starrylandscapestacker/home">
              <strong>Starry Landscape Stacker</strong>
            </Lien>{" "}
            (Mac) ($ 40). It does the same as Sequator, but on{" "}
            <strong>Mac</strong>!
          </li>
        </ul>
      </Section>
      <Section titre="Noise reduction">
        <ul>
          <li>
            <Lien urlExterne="https://topazlabs.com/denoise-ai/">
              <strong>Topaz Denoise AI</strong>
            </Lien>{" "}
            ($ 80) (Windows, Mac): an extremely efficient software for {""}
            noise reduction, but also {""}
            <strong>to gain in resolution</strong>. It also offers a plugin for
            Photoshop. But be careful, you must use it{" "}
            <strong>sparingly</strong> otherwise you will get an image that is
            too smooth and too artificial (the software is able to “invent”
            details).
          </li>
        </ul>
      </Section>
      <Section titre="Deep-sky astrophotography preprocessing">
        <p>
          In astrophotography, the goal of <strong>preprocessing</strong> is to
          create an image with <strong> as few defects as possible</strong>{" "}
          (noise, optical defects…) from a series of identical images. This
          image will then be used as a working basis for final processing in
          Photoshop. Deep-sky astrophotography is the discipline consisting in
          photographing celestial objects located outside of our Solar System
          (nebulae, galaxies, star clusters…).
        </p>
        <ul>
          <li>
            <Lien urlExterne="http://deepskystacker.free.fr/english/index.html">
              <strong>Deep Sky Stacker</strong>
            </Lien>{" "}
            (Windows): Deep Sky Stacker is the <strong>easiest</strong> software
            for deep-sky astrophotography preprocessing. I highly recommend it
            to begin. Just select your images, your darks, your offsets and your
            flats, and start the stacking process.
          </li>
          <li>
            <Lien urlExterne="https://www.siril.org/fr/">
              <strong>SiriL</strong>
            </Lien>{" "}
            (Windows, Mac, Linux): the latest one. Based on the venerable but
            excellent software {""}
            <Lien urlExterne="http://www.astrosurf.com/buil/iris-software.html">
              Iris
            </Lien>
            , it offers its many features through an up-to-date and intuitive
            graphical interface. It is <strong>much more powerful</strong> than
            Deep Sky Stacker but nevertheless remains a{" "}
            <strong>little more complicated</strong> to use.
          </li>
          <li>
            <Lien urlExterne="https://www.astropixelprocessor.com/">
              <strong>Astro Pixel Processor</strong>
            </Lien>{" "}
            (€ 72 / year) (Windows, Mac, Linux): <strong>very powerful</strong>,
            very <strong>regularly updated</strong>, it has many settings. Its
            “debayarization” algorithm is very efficient: it gives more details
            compared to an image made with Deep Sky Stacker. Astro Pixel
            Processor excels also in the assembly of large celestial mosaics: my
            astrophotographs “<Lien codePageCible="orionZoomEN">Orion 360</Lien>
            ” and “<Lien codePageCible="megalaxyZoomEN">Megalaxy Zoom</Lien>”
            were assembled with it. On the other hand, its many settings and its
            austere graphical interface can make the handling of the Astro Pixel
            Processor a little bit complex.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "logicielsLelogicielAstroPixelProcessor")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Lunar and planetary images processing">
        <ul>
          <li>
            <Lien urlExterne="https://sites.google.com/site/astropipp/">
              <strong>PIPP</strong>
            </Lien>{" "}
            (Windows): a small and extremely efficient software to{" "}
            <strong>pre-process lunar and planetary images</strong>. It
            facilitates stacking in third-party software like Photoshop or
            Registax. It does alignment, selects the best images, and more, such
            as converting video formats.
          </li>
          <li>
            <Lien urlExterne="http://www.astronomie.be/registax/">
              <strong>Registax</strong>
            </Lien>{" "}
            (Windows): an excellent software specialized in{" "}
            <strong>aligning</strong>, <strong>stacking</strong> and{" "}
            <strong>processing</strong> lunar and planetary images. It has
            powerful processing algorithms specialized for this
            astrophotographic discipline like the wavelets processing (which
            reveals the details of the lunar disk for example).
          </li>
        </ul>
      </Section>
      <Section titre="Acquisition">
        <ul>
          <li>
            <Lien urlExterne="https://mainsequencesoftware.com/products/sgpro/">
              <strong>Sequence Generator Pro</strong>
            </Lien>{" "}
            ($ 149 then $ 59 per year) (Windows): this software is capable {""}
            <strong>
              to completely automate a deep-sky imaging acquisition
            </strong>
            : focus (if your telescope is equipped with a motorized focuser),
            framing, meridian flip… It also allows to completely automate the
            acquisition of large mosaics. Automating a telescope may be long and
            you have to be patient. But it’s worth it, what a tranquility once
            everything is working…
          </li>
          <Image
            image={obtenirImage(
              images,
              "logicielsLelogicielSequenceGeneratorPro"
            )}
            langue={page.langue}
          />
          <li>
            <Lien urlExterne="https://nighttime-imaging.eu/">
              <strong>NINA</strong>
            </Lien>{" "}
            (Windows) : like Sequence Generator Pro, this software allows {""}
            <strong>to completely automate</strong> a deep-sky imaging
            acquisition. NINA is very powerful, has the same features as
            Sequence Generator Pro, but it can be a little less stable.
          </li>
          <Image
            image={obtenirImage(images, "logicielsNina")}
            langue={page.langue}
          />
          <li>
            <Lien urlExterne="https://astrophotography.app/index.php">
              <strong>Astro Photography Tools</strong>
            </Lien>{" "}
            (Windows) (€ 19) : this true swiss army knife of the
            astrophotographer allows you to manage and even{" "}
            <strong>automate your deep sky astrophotography sessions</strong>.
            However, it is not as powerful as Sequence Generator Pro, but it is
            easier to use. A great tool if you have a DSLR (it was originally
            designed for these cameras).
          </li>
          <li>
            <Lien urlExterne="https://openphdguiding.org">
              <strong>PHD Guiding</strong>
            </Lien>{" "}
            (Windows, Mac, Linux): the software par excellence for autoguiding
            an equatorial mount to correct tracking errors during long exposure
            times.
          </li>
        </ul>
      </Section>
    </LayoutTutorielEN>
  )
}
